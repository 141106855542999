<template>
  <div class="d-flex flex-column flex-root" v-loading="loading">
    <div class="header">
      <div class="overlay"></div>
      <div class="text">
        <span class="text-logo custom-text-header">
          <img :src="url" alt="">
        </span>
      </div>
    </div>
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-outside"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center overflow-hidden p-7 mx-auto bg-inside"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg" style="text-align: center"
                >
                  ログイン
                </h3>
                <!-- <span class="text-muted font-weight-bold font-size-h4"
                  >ここで新しい?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="goToRegister()"
                    >アカウントを作成する</a
                  ></span> -->
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >メールアドレス</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">パスワード</label>
                  <!-- <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="goToForgotPassword()"
                    >パスワードを忘れた方</a
                  > -->
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5 text-center">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  ログイン
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import { STATUS_CODE } from "@/core/config/constant";
import { ENV } from "@/core/config/env";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      loading: false,
      url: ''
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/logos/rogo.png"
      );
    }
  },
  created() {
    this.url = `${ENV.PREFIX_FOLDER}/template/images/logo-right.png`;
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t('ER_02', {field: 'メールアドレス'}),
            },
            stringLength: {
              max: 100,
              message: this.$t("maxLength", {name: 'メールアドレス', length: '100'})
            },
            emailAddress: {
              message: this.$t('ER_06'),
            },

          }
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t('ER_02', {field: 'パスワード'}),
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then((response) => {
            if (response.code == STATUS_CODE.SUCCESS) {
              this.$router.push({ name: "canlendar" })
              this.notifySuccess('ログインしました。')
            } else if (response.code == STATUS_CODE.BAD_REQUEST) {
              this.notifyError(this.$t('ER_07'));
              submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
            } else {
              this.notifyError(this.$t("errorServer"))
              submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
            }
          }).catch(error => {
            this.notifyError(this.$t("errorServer"))
            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          })
    });
  },

  methods: {

    goToRegister(){
      this.$router.push({ name: "register" });
    },

    goToForgotPassword(){
      this.$router.push({ name: "forgot-password" });
    },
  }
};
</script>

<style>
.header {
    height: 80px;
    width: 100%;
}
.header .text {
    height: 80px;
    width: 100%;
    line-height: 80px;
    color: #0027FF;
    position: relative;
    z-index: 10;
    text-align: center;
    font-family: Tahoma;
    font-size: 36px !important;
    font-weight: bold;
    background-color: #027DB4;
}
.custom-text-header{
  text-transform: uppercase;
  color:white
}
.text-logo{
  float:left;
  margin-left:25px;
}
.bg-outside {
  background-color: #80A9BB;
  position: relative;
}
.bg-inside {
  position: absolute;
  background-color: white;
  height: 500px;
  left: 0;
  right: 0;
  top: 20%;
  max-width: 650px !important;
}
</style>